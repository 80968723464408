import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../component/Other/Navbar";
import VideoXL from "../../component/Other/VideoXL";
import SliderVideo from "../../component/Slider/SliderVideo";

const Index = (props) => {
  const portofolio =
  {
    poster: "./../images/Enver Showreel (1).jpg",
    video: "https://enverstudio-path.s3.us-east-1.amazonaws.com/Enver%20Showreel%20(1).mp4",
    title: "Killaz Concept Trailer"
  };

  const projectFInal = [
    {
      poster: "./../images/Astro Hounds.jpg",
      video: "https://vfx-path.s3.amazonaws.com/Astro+Hounds.mp4",
      title: "Astro Hounds"
    },
    {
      poster: "./../images/Brokli Trailer.jpg",
      video: "https://vfx-path.s3.amazonaws.com/Brokli+Trailer.mp4",
      title: "Brokli Trailer"
    },
    {
      poster: "./../images/Killaz Concept Trailer.jpg",
      video: "https://vfx-path.s3.amazonaws.com/Killaz+Concept+Trailer.mp4",
      title: "Killaz Concept Trailer"
    },
    {
      poster: "./../images/Killaz Event (Mixed).jpg",
      video: "https://vfx-path.s3.amazonaws.com/Killaz+Event+(Mixed).mp4",
      title: "Killaz Event"
    },
    {
      poster: "./../images/Metafi Trailer (1).jpg",
      video: "https://vfx-path.s3.amazonaws.com/Metafi+Trailer+(1).mp4",
      title: "Metafi Trailer"
    },
    {
      poster: "./../images/Primal Trailer.jpg",
      video: "https://vfx-path.s3.amazonaws.com/Primal+Trailer.mp4",
      title: "Primal Trailer"
    },
    {
      poster: "./../images/Tanks Trailer.jpg",
      video: "https://vfx-path.s3.amazonaws.com/Tanks+Trailer.mp4",
      title: "Tanks Trailer"
    }
  ]

  const Slider = [
    {
      poster: "./../images/solo-76e99d35-5eb6-48f7-9b6e-b4090e843c36.jpg",
      video: "https://vfx-path.s3.amazonaws.com/solo-76e99d35-5eb6-48f7-9b6e-b4090e843c36.MP4",
      title: "Brokli Trailer"
    },
    {
      poster: "./../images/solo-8521290a-2843-442a-846d-5807db951208.jpg",
      video: "https://vfx-path.s3.amazonaws.com/solo-8521290a-2843-442a-846d-5807db951208.mp4",
      title: "Killaz Concept"
    },
    {
      poster: "./../images/solo-F1 Inspo.jpg",
      video: "https://vfx-path.s3.amazonaws.com/solo-F1+Inspo.mp4",
      title: "Brokli Trailer"
    },
    {
      poster: "./../images/solo-Skyscraper.jpg",
      video: "https://vfx-path.s3.amazonaws.com/solo-Skyscraper.MP4",
      title: "Killaz Concept"
    },
    {
      poster: "./../images/solo-Twitter Bird.jpg",
      video: "https://vfx-path.s3.amazonaws.com/solo-Twitter+Bird.mp4",
      title: "Brokli Trailer"
    },
    {
      poster: "./../images/SpidermanRender_06_Sound.jpg",
      video: "https://vfx-path.s3.amazonaws.com/SpidermanRender_06_Sound.mp4",
      title: "Killaz Concept"
    }
  ]
  return (
    <Fragment>
      <div className="overflow-hidden bg__darkblue">
        <Navbar />
        <section className="position-relative">
          <img src="./../images/Group 162886.png" className="path__1 d-none d-sm-block" alt="" />
          <div className="container position-relative z-2">
            <div className="text-center">
              <h1 className="bold font__size--64 text__65-1024 text__65-sm text__65-xx mb-5">VFX PORTFOLIO</h1>

              <div className="d-flex justify-content-center align-items-center">
                <VideoXL data={portofolio} class={"size-xl"} />
              </div>

              <h3 className="bold font__size--36 text__36-1024 mt-4">Show Reel</h3>

            </div>
          </div>
        </section>
        <section className="bg__darkblue-2">
          <div className="container position-relative z-2">
            <div className="text-center">

              <h1 className="bold font__size--46 text__45-1024 mb-5">PROJECT FINALS</h1>

              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-10 col-xl-8">
                  <div className="row justify-content-center">
                    {
                      projectFInal.map((obj) => {
                        return <div className="col-md-6 mb-5">
                          <VideoXL data={obj} class={"size-sm"} />
                          <h5 className="bold font__size--28 text__28-1024 mt-3 text-left">{obj.title}</h5>
                        </div>
                      })
                    }

                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="text-center">

              <h1 className="bold font__size--46 text__45-1024 mb-5">INVDIVIDUAL SHOTS</h1>

              <SliderVideo data={Slider} />

            </div>
          </div>
        </section>

        <section className="bg__darkblue-2 pb-5">
          <div className="container text-center text-md-left">
            <div className="row mb-5">
              <div className="col-md-3">
                <img src="./../images/Asset 7@2x 2 (1).png" alt="" />

                <div className="mt-5">
                  <h5 className="semi-bold font__size--14 text__14-1024">hi@enverstudio.com</h5>
                  <h5 className="semi-bold font__size--14 text__14-1024">San Diego, CA - USA</h5>
                </div>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-3 mt-auto">
                <div className="mt-5">
                  <h5 className="semi-bold font__size--14 text__14-1024">Full Website</h5>
                  <a href="https://www.enverstudio.com/" className="semi-bold color__white opacity__5 hover font__size--14 text__14-1024">Enverstudio.com</a>
                </div>
              </div>
            </div>
            <div className="text-center semi-bold font__size--12 text__12-1024 opacity__5">
              ©2021 Enver Studio - All Rights Reserved
            </div>
          </div>
        </section>

      </div >
    </Fragment >
  );
};

export default Index;
