import React, { Component, Fragment } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = (props) => {

  return (
    <Fragment>
      <nav className="wrapper__navbar">
        <div className="d-flex align-items-center position-relative z-2">
          <div className="items logo position-relative z-1">
            <img src="./../images/Asset 3@2x 2 (2).png" alt="" />
          </div>
          <div className="center bold font__size--24 text__24-1024 d-sm-block d-none z-3">
            ENVER VFX
          </div>
          <div className="items ml-auto">
            <a href="https://www.enverstudio.com/" className="semi-bold font__size--16 text__16-1024 color__white position-relative z-1">Full Website</a>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
