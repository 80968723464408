import React, { Component, Fragment, useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import VideoXL from '../Other/VideoXL';
const SliderVideo = (props) => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
              breakpoint: 580,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]
    };

    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickNext();
    };

    const next = () => {
        slider1.current.slickPrev();
    };
    return (
        <Fragment>
            <div className="position-relative">
                <div className="wrapper__arrow d-none d-lg-block">
                    <img src="./../images/Group 162622 (2).svg" onClick={next} className='pointer left' alt="" />
                    <img src="./../images/Group 162619 (2).svg" onClick={previous} className='pointer right' alt="" />
                </div>
                <div className="row justify-content-center mb-4 mb-lg-0">
                    <div className="col-lg-10 col-xl-8">
                        <Slider
                            {...settings}
                            ref={slider1} className="wrapper__slider-wrap">
                            {
                                props.data.map((obj) => {
                                    return <div className="items">
                                        <VideoXL data={obj} class={"size-sm"} />
                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                </div>

                <div className="wrapper__arrow-sm d-flex align-items-center justify-content-center d-lg-none">
                    <img src="./../images/Group 162622 (2).svg" onClick={next} className='pointer left' alt="" />
                    <img src="./../images/Group 162619 (2).svg" onClick={previous} className='pointer right ml-5' alt="" />
                </div>

            </div>
        </Fragment>
    )
}

export default SliderVideo;