import React, { Component, Fragment, useEffect, useRef, useState } from 'react'

const VideoXL = (props) => {
    const video = useRef(null);

    const [play, setPlay] = useState(false);

    const tootlePlay = () => {
        if (!play) {
            video.current.play();
        } else {
            video.current.pause();
        }

        setPlay(!play);
    };

    const playOnload = async (e) => {
        await e.current?.load();
    };

    return (
        <Fragment>
            <div className={"wrapper__video position-relative " + (props.class ? props.class : "") + " " + (play ? "playing" : "")}>
                <video
                    ref={video}
                    poster={props.data.poster}
                    className="video"
                    controls={true}
                >
                    <source
                        src={props.data.video}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
                <img src={props.data.poster} className='poster' alt="" />
                <img src="./../images/Vector 18.png" className='frame-1' alt="" />
                <img src="./../images/Vector 16.png" className='frame-2' alt="" />
                <img src="./../images/Vector 83.png" className='frame-3' alt="" />
                <img src="./../images/Vector 22.png" className='frame-4' alt="" />

                <div className="wrapper__play z-2">
                    <div className="position-relative">
                        <div className="icon">
                            <div className="button">
                                <img
                                    src="./../images/play_circle_filled.png"
                                    onClick={() => tootlePlay()}
                                    className={"pointer play " + (play ? "d-none" : "")}
                                    alt=""
                                />
                                <img
                                    src="./../images/pause_circle_filled (1).png"
                                    onClick={() => tootlePlay()}
                                    className={"pointer paus " + (play ? "" : "d-none")}
                                    alt=""
                                />
                            </div>
                        </div>
                        <img src="./../images/Polygon 1.png" className="cover" alt="" />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default VideoXL;